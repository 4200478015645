<template>
  <div
    class="dev-tools"
    @mouseenter="showDevTools = true"
    @mouseleave="showDevTools = false"
  >
    <template v-if="showDevTools">
      <form class="dev-nav" @submit.prevent="handleGoToSlide(slideIdOrName)">
        <b-form-input
          v-model="slideIdOrName"
          type="text"
          placeholder="slideIdOrName"
          class="nav-input"
        />
        <b-button
          variant="primary"
          class="nav-button"
          aria-label="go to slide"
          @click="handleGoToSlide(slideIdOrName)"
        >
          <span>Go To Slide</span>
        </b-button>
      </form>
      <div class="dev-info">
        <p>
          SlideId:
          <input
            :value="currentSlide?.id"
            style="width: 100%; font-size: 9pt"
            @focus="$event.target.select()"
          >
        </p>
        <p>
          Period: <strong>{{ currentPeriod?.name }}</strong><span v-if="currentPeriod.phase" style="color: seagreen; font-size: 1.2em"><strong> - Phase: {{ currentPeriod?.phase }}</strong></span>
        </p>
        <p>&emsp;-> Stage: <strong>{{ currentStage?.name }}</strong></p>
        <p>&emsp;&emsp;-> Step: <strong>{{ currentStep?.name }}</strong></p>
        <p>&emsp;&emsp;&emsp; -> Slide: <strong>{{ currentSlide?.name }}</strong></p>
        <p>Slide Layout: <strong>{{ slideLayoutType }}</strong></p>
        <p>agencyResourceId: <strong>{{ agencyResourceId }}</strong></p>
        <p>Completed Slide Count: <strong>{{ completedSlideCount }}</strong></p>
        <p>Slide is Last: <strong>{{ slideIsLast }}</strong></p>
      </div>
      <b-checkbox v-model="debugDocumentViewerEditMode">
        <span style="color: red;">Force Document Viewer Edit Mode</span>
      </b-checkbox>
      <div class="d-flex space-between">
        <b-button
          variant="primary"
          class="nav-button"
          aria-label="force previous"
          @click="goToPreviousSlide"
        >
          <span>Force Previous</span>
        </b-button>
        <b-button
          variant="primary"
          class="nav-button"
          aria-label="force next"
          @click="goToNextSlide"
        >
          <span>Force Next</span>
        </b-button>
      </div>
    </template>
    <div v-else>
      Show Dev Tools
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'StagelineDevInfo',
  mixins: [makeToastMixin],
  data() {
    return {
      slideIdOrName: '',
      showDevTools: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentPeriod',
      'currentStage',
      'currentStep',
      'currentSlide',
      'currentPeriodSlides',
      'filteredPeriodSlideList',
      'slideIsLast',
    ]),
    ...mapGetters('stagelineSchemaForm', [
      'agencyResourceId',
    ]),
    debugDocumentViewerEditMode: {
      get() {
        return this.$store.state.stagelineSchemaForm.debugDocumentViewerEditMode
      },
      set(bool) {
        this.$store.dispatch('stagelineSchemaForm/setDebugDocumentViewerEditMode', bool)
      },
    },
    slideLayoutType() {
      return this.currentSlide ? this.currentSlide.layout_type : 'resting'
    },
    completedSlideCount() {
      return this.currentPeriod ?
        this.filteredPeriodSlideList(this.currentPeriod.name).filter(slide => slide.completed_at !== null).length
        : 0
    },
  },
  methods: {
    ...mapActions('stageline', [
      'goToSlide',
      'goToNextSlide',
      'goToPreviousSlide',
    ]),
    handleGoToSlide(slideIdOrName) {
      if (this.currentPeriodSlides.some(slide => [slide.id, slide.name].includes(slideIdOrName))) {
        this.goToSlide(slideIdOrName)
      } else {
        this.errorToast('Error', `${slideIdOrName} not found in currentPeriodSlides`)
      }
      this.slideIdOrName = ''
    },
  },
}
</script>
<style lang="scss" scoped>

.dev-tools {
  font-size: .9em;
  opacity: 0.5;
  min-height: 25px !important;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 10000;
  background: white;
  border: black solid 2px;
  padding: 2px;
  height: 20px;
  //width: 100px;
  &:hover {
    opacity: 0.9;
    height: unset;
    width: unset;
   }

  .dev-nav {
    display: inline-flex;
    width: 100%;

    .nav-input {
      flex: 7;
      min-height: 1.6rem !important;
      height: 1.6rem;
      font-size: 0.9em;
    }
  }

  .nav-button {
    flex: 3;
    height: 1.5rem;
    padding: 0.15rem 0.5rem;
    margin-left: 2px;
    background: $ct-ui-primary;

    span {
      vertical-align: top;
      font-size: 0.7em;
    }
  }

  .dev-info {
    p {
      margin-bottom: 5px !important;
    }
  }
}
</style>
