var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dev-tools",
      on: {
        mouseenter: function ($event) {
          _vm.showDevTools = true
        },
        mouseleave: function ($event) {
          _vm.showDevTools = false
        },
      },
    },
    [
      _vm.showDevTools
        ? [
            _c(
              "form",
              {
                staticClass: "dev-nav",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleGoToSlide(_vm.slideIdOrName)
                  },
                },
              },
              [
                _c("b-form-input", {
                  staticClass: "nav-input",
                  attrs: { type: "text", placeholder: "slideIdOrName" },
                  model: {
                    value: _vm.slideIdOrName,
                    callback: function ($$v) {
                      _vm.slideIdOrName = $$v
                    },
                    expression: "slideIdOrName",
                  },
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "nav-button",
                    attrs: { variant: "primary", "aria-label": "go to slide" },
                    on: {
                      click: function ($event) {
                        return _vm.handleGoToSlide(_vm.slideIdOrName)
                      },
                    },
                  },
                  [_c("span", [_vm._v("Go To Slide")])]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "dev-info" }, [
              _c("p", [
                _vm._v("\n        SlideId:\n        "),
                _c("input", {
                  staticStyle: { width: "100%", "font-size": "9pt" },
                  domProps: { value: _vm.currentSlide?.id },
                  on: {
                    focus: function ($event) {
                      return $event.target.select()
                    },
                  },
                }),
              ]),
              _c("p", [
                _vm._v("\n        Period: "),
                _c("strong", [_vm._v(_vm._s(_vm.currentPeriod?.name))]),
                _vm.currentPeriod.phase
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          color: "seagreen",
                          "font-size": "1.2em",
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v(
                            " - Phase: " + _vm._s(_vm.currentPeriod?.phase)
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("p", [
                _vm._v(" -> Stage: "),
                _c("strong", [_vm._v(_vm._s(_vm.currentStage?.name))]),
              ]),
              _c("p", [
                _vm._v("  -> Step: "),
                _c("strong", [_vm._v(_vm._s(_vm.currentStep?.name))]),
              ]),
              _c("p", [
                _vm._v("    -> Slide: "),
                _c("strong", [_vm._v(_vm._s(_vm.currentSlide?.name))]),
              ]),
              _c("p", [
                _vm._v("Slide Layout: "),
                _c("strong", [_vm._v(_vm._s(_vm.slideLayoutType))]),
              ]),
              _c("p", [
                _vm._v("agencyResourceId: "),
                _c("strong", [_vm._v(_vm._s(_vm.agencyResourceId))]),
              ]),
              _c("p", [
                _vm._v("Completed Slide Count: "),
                _c("strong", [_vm._v(_vm._s(_vm.completedSlideCount))]),
              ]),
              _c("p", [
                _vm._v("Slide is Last: "),
                _c("strong", [_vm._v(_vm._s(_vm.slideIsLast))]),
              ]),
            ]),
            _c(
              "b-checkbox",
              {
                model: {
                  value: _vm.debugDocumentViewerEditMode,
                  callback: function ($$v) {
                    _vm.debugDocumentViewerEditMode = $$v
                  },
                  expression: "debugDocumentViewerEditMode",
                },
              },
              [
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("Force Document Viewer Edit Mode"),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex space-between" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "nav-button",
                    attrs: {
                      variant: "primary",
                      "aria-label": "force previous",
                    },
                    on: { click: _vm.goToPreviousSlide },
                  },
                  [_c("span", [_vm._v("Force Previous")])]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "nav-button",
                    attrs: { variant: "primary", "aria-label": "force next" },
                    on: { click: _vm.goToNextSlide },
                  },
                  [_c("span", [_vm._v("Force Next")])]
                ),
              ],
              1
            ),
          ]
        : _c("div", [_vm._v("\n    Show Dev Tools\n  ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }